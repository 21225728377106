import React from 'react'

const PlopsaHotel = () => {
  return (
    <div className="free-banner last has-text-centered">
      <a
        href="https://www.plopsa.be/kabouter/?tt=27749_2171171_436802_&amp;r="
        target="_blank"
        rel="sponsored nofollow"
      >
        <img
          src="https://ti.tradetracker.net/?c=27749&amp;m=2171171&amp;a=436802&amp;r=&amp;t=html"
          width="728"
          height="90"
          border="0"
          alt="Plopsa Hotel"
        />
      </a>
    </div>
  )
}

export default PlopsaHotel
