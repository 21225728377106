import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default class Featured extends React.Component {
  render() {
    const featuredMedia = this.props.src

    return (
      <div className="featured img-marg is-clearfix ">
        <GatsbyImage
          image={
            featuredMedia.node?.localFile?.childImageSharp?.gatsbyImageData
          }
        />
      </div>
    )
  }
}
