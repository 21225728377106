import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SocialSharing from '../components/SocialSharing/SocialSharing'
import LinkCloud from '../components/LinkCloud/LinkCloud'
import Plopsa from '../components/Affiliates/Plopsa'
import Disney from '../components/Affiliates/Disney'
import PlopsaHotel from '../components/Affiliates/PlopsaHotel'
import PlopsaStation from '../components/Affiliates/PlopsaStation'
import Helmet from 'react-helmet'
import NewsList from '../components/NewsList'

export default class Nieuws extends React.Component {
  render() {
    const { data } = this.props
    const { edges: nieuwsberichten } = data.allWpNieuwsbericht
    const { siteUrl, title: siteTitle } = data.site.siteMetadata

    return (
      <Layout path="/">
        <Helmet>
          <title>{`Nieuws | ${siteTitle}`}</title>
          <link rel="canonical" href={`${siteUrl}/nieuws/`} />
          <meta name="description" content="Laatste nieuws pretparken" />
          <meta name="og:title" content={`Nieuws | ${siteTitle}`} />
          <meta name="og:description" content="Laatste nieuws pretparken" />
          <meta name="og:url" content="https://pretparkvoordeel.be/nieuws/" />
        </Helmet>
        <NewsList news={nieuwsberichten} title="Nieuws" />
        <section className="section-post">
          <div className="container">
            <Disney />
            <Plopsa />
            <PlopsaStation />
            <PlopsaHotel />
            <SocialSharing shareUrl={siteUrl} />
            <LinkCloud />
          </div>
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query NewsListQuery {
    allWpNieuwsbericht(sort: { fields: date, order: DESC }) {
      edges {
        node {
          ...NewsListFields
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
