import React from 'react'

const PlopsaStation = () => {
  return (
    <div className="free-banner last has-text-centered">
      <a
        href="https://www.plopsa.be/kabouter/?tt=3059_1929197_329258_&amp;r="
        target="_blank"
        rel="sponsored nofollow"
      >
        <img
          src="https://ti.tradetracker.net/?c=3059&amp;m=1929197&amp;a=329258&amp;r=&amp;t=html"
          width="728"
          height="90"
          border="0"
          alt="Plopsa kies het abonnement dat bij je past "
        />
      </a>
    </div>
  )
}

export default PlopsaStation
