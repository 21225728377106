import React from 'react'

export default class Disney extends React.Component {
  render() {
    const uri =
      'https://imp.tradedoubler.com/imp?type(img)g(25188578)a(3118728)' +
      new String(Math.random()).substring(2, 11)

    return (
      <div className="free-banner last has-text-centered">
        <a
          href="https://clk.tradedoubler.com/click?p=21060&a=3118728&g=25188578"
          target="_blank"
          rel="noreferrer"
        >
          <img src={uri} border="0" alt="Disney" />
        </a>
      </div>
    )
  }
}
