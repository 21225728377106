import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import Featured from './Featured/Featured'

const NewsList = props => {
  const { news, title } = props

  return (
    <section className="section section-news ">
      <div className="container">
        <div className="content">
          <h1 className="title has-text-white has-text-centered">{title}</h1>
          <div className="columns  is-multiline">
            {news
              ? news.map(({ node: newsItem }) => (
                  <>
                    <div className="column is-6 is-flex" key={newsItem.id}>
                      <div className="tile-inner withholding-pattern is-flex">
                        <h2 className="tile-title has-text-centered text-decoration-none">
                          <Link to={`/nieuws/${newsItem.slug}`}>
                            <span className="light">-</span>{' '}
                            {parse(newsItem.title)}{' '}
                            <span className="light">-</span>{' '}
                          </Link>
                        </h2>
                        <div className="date has-text-centered">
                          {newsItem.date}
                        </div>
                        {newsItem.featuredImage ? (
                          <Featured src={newsItem.featuredImage} />
                        ) : null}
                        <div className="column is-12 tile-content is-flex is-flex-direction-column flex-grow-1">
                          <div
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: newsItem.content.slice(
                                0,
                                newsItem.content.indexOf('<!--more-->')
                              ),
                            }}
                          />
                        </div>
                        <div className="tile-cta has-text-centered">
                          {!newsItem.extra.upfront ? (
                            <div>
                              <Link
                                className="button has-text-white is-medium is-danger mt-5"
                                to={`/nieuws/${newsItem.slug}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Lees het volledige artikel
                              </Link>
                            </div>
                          ) : null}
                          {newsItem.extra.urlActie ? (
                            <div>
                              <a
                                className="button has-text-white is-medium is-danger mt-5"
                                href={newsItem.extra.urlActie}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {newsItem.extra.ctaButtonText
                                  ? newsItem.extra.ctaButtonText
                                  : 'Profiteer nu van de korting'}
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsList

NewsList.propTypes = {
  news: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  data: PropTypes.shape({
    allWordpressCategory: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
